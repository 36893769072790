// import Core
import React, { useState, useEffect } from "react";
// import Material UI
import {
	Grid,
	Typography,
	TextField,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
} from "@material-ui/core";
// list of countries for country dropdown
import countries from "./countries";

export default function AddressForm(props) {
	const [FirstName, setFirstName] = useState("");
	const [Surname, setSurname] = useState("");
	const [Email, setEmail] = useState("");
	const [Address, setAddress] = useState({
		Street: "",
		City: "",
		Zip: "",
		CountryISO2: "",
	});
	// const [checked, setChecked] = useState(false);

	const { roomNo, guestNo, updateBookingState } = props;

	useEffect(() => {
		const { guest } = props;
		setFirstName(guest.FirstName);
		setSurname(guest.Surname);
		setEmail(guest.Email);
		setAddress(guest.Address);
	}, [props]);

	// useEffect(() => {
	//   if (checked) {
	//     props.checkedAddress(checked);
	//   }
	// }, [checked, props]);

	//go back and forth on steps deletes typed ins tuff need to update state
	return (
		<>
			<div className='greybox'>
				<Typography variant='h6' gutterBottom>
					{!guestNo ? "Primary Guest" : `Additional Guest ${guestNo}`}
				</Typography>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>
						<TextField
							required
							id='FirstName'
							name='FirstName'
							label='First name'
							fullWidth
							autoComplete='fname'
							onChange={(e) =>
								updateBookingState(
									e.target.value,
									roomNo,
									guestNo,
									e.target.name
								)
							}
							value={FirstName}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							required
							id='Surname'
							name='Surname'
							label='Last name'
							fullWidth
							autoComplete='lname'
							onChange={(e) =>
								updateBookingState(
									e.target.value,
									roomNo,
									guestNo,
									e.target.name
								)
							}
							value={Surname}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							required
							id='Email'
							name='Email'
							label='E-mail'
							fullWidth
							onChange={(e) =>
								updateBookingState(
									e.target.value,
									roomNo,
									guestNo,
									e.target.name
								)
							}
							value={Email}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							required
							id='Street'
							name='Street'
							label='Street'
							fullWidth
							autoComplete='billing address-line1'
							onChange={(e) =>
								updateBookingState(
									e.target.value,
									roomNo,
									guestNo,
									e.target.name,
									"Address"
								)
							}
							value={Address.Street}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							required
							id='City'
							name='City'
							label='City'
							fullWidth
							autoComplete='billing address-level2'
							onChange={(e) =>
								updateBookingState(
									e.target.value,
									roomNo,
									guestNo,
									e.target.name,
									"Address"
								)
							}
							value={Address.City}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							required
							id='Zip'
							name='Zip'
							label='Zip / Postal code'
							fullWidth
							autoComplete='billing postal-code'
							onChange={(e) =>
								updateBookingState(
									e.target.value,
									roomNo,
									guestNo,
									e.target.name,
									"Address"
								)
							}
							value={Address.Zip}
						/>
					</Grid>
					{/* <Grid item xs={12} sm={6}>
						<TextField
							required
							id='Country'
							name='CountryISO2'
							label='Country'
							fullWidth
							autoComplete='billing country'
							onChange={(e) =>
								updateBookingState(
									e.target.value,
									roomNo,
									guestNo,
									e.target.name,
									"Address"
								)
							}
							value={Address.CountryISO2}
						/>
					</Grid> */}
					{/* <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                name="saveAddress"
                value="yes"
                checked={checked}
                onChange={e => setChecked(e.target.checked)}
              />
            }
            label="Use this address for payment details"
          />
        </Grid> */}

					<Grid item xs={12} sm={6}>
						<FormControl required>
							<InputLabel id='country-select-required-label'>
								Country
							</InputLabel>
							<Select
								labelId='country-select-required-label'
								id='country-select-required'
								name='CountryISO2'
								label="Age"
								fullWidth
								autoComplete='billing country'
								onChange={(e) =>
									updateBookingState(
										e.target.value,
										roomNo,
										guestNo,
										e.target.name,
										"Address"
									)
								}
								value={Address.CountryISO2}
							>
								{countries.map((country) => (
									<MenuItem value={country.code} key={country.code}>
										{country.label}, {country.code}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			</div>
		</>
	);
}
