import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import "./App.scss";
import LinearProgress from "@material-ui/core/LinearProgress";
// import Rooms from "./components/Rooms";
import Checkout from "./components/Checkout";
import TagManager from "react-gtm-module";
import Logo from "./components/logo_access.png";

// Add Google Tag Manager
const tagManagerArgs = {
  gtmId: "GTM-T25KZGJ",
};
TagManager.initialize(tagManagerArgs);

// goes in component did mount
// const userID = new URLSearchParams(window.location.search).get("id"); below is hardcoded  for working on local
// const userID = 600483011;
// Here is another comment
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://accessaccommodationlondon.co.uk/">
        Access Accommodation. Powered by Umi.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

class App extends React.Component {
  constructor() {
    super();
    this.state = { reservationFound: null };
  }

  componentDidMount() {
    document.location.search.startsWith("?id=")
      ? this.GETBookingFromServer()
      : alert(
        "The URL you have searched for is missing the id, please use link provided in email or please contact us directly"
      );
  }

  GetDueBalance = async () => {
    var parseQueryString = function () {
      var str = window.location.search;
      var objURL = {};

      str.replace(
        new RegExp("([^?=&]+)(=([^&]*))?", "g"),
        function ($0, $1, $2, $3) {
          objURL[$1] = $3;
        }
      );
      return objURL;
    };
    var params = parseQueryString();
    const id = params["id"];
    const response = await fetch("/booking/dueBalance/", {
      method: "POST",
      cache: "no-cache",
      body: JSON.stringify({ IDReservation: id }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    let json = await response.json();
    if (json.IsSuccess) {
      const balanceArray = json.Data.map(obj => {
        return obj.Header.DueBalance;
      });

      const balance = balanceArray.reduce((a, b) => a + b, 0);

      this.setState({
        dueBalance: balance,
      });
    }
  };

  GETBookingFromServer = async () => {
    fetch(`/Reservation/${document.location.search}`)
      .then(response => response.json())
      .then(json => {
        if (json.IsSuccess) {
          this.setState({
            booking: json.Data,
          });
          this.setState({
            reservationFound: true,
          });
          this.GetDueBalance();
        } else {
          throw new Error(
            "Information returned was unsuccessful, the user ID at the end of the URL may be incorrect. \nPlease Get in contact with Access Accommodation"
          );
        }
      })
      .catch(err => {
        this.setState({
          reservationFound: false,
        });
      });
  };

  updateBookingState = (value, room, guest, detail, objDetail) => {
    const updatedBooking = this.state.booking;

    if (value !== undefined) {
      if (objDetail) {
        updatedBooking.Rooms[room].Guests[guest][objDetail][detail] = value;
      } else {
        updatedBooking.Rooms[room].Guests[guest][detail] = value;
      }
    } else {
      console.log(
        "Parameters must be passed when using the update booking function, please review code"
      );
    }

    this.setState({
      booking: updatedBooking,
    });
  };

  PUTUpdateGuests = () => {
    if (this.state.booking) {
      this.state.booking.Rooms.forEach(room => {
        room.Guests.forEach(guest => {
          const data = {
            IDReservation: this.state.booking.ID,
            IDCitizen: guest.ID,
            Citizen: guest,
          };

          fetch("/Guest/Update", {
            method: "PUT",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
              "Content-Type": "application/json",
            },
          });
        });
      });
    }
  };

  POSTPrePay = () => {
    if (this.state.booking) {
      const data = {
        IDReservation: this.state.booking.ID,
        AdvanceText: "Payment Taken Via Stripe and Check In Web App",
        IDPaymentType: 7,
        PaymentCurrencyISO: this.state.booking.CurrencyISO,
        Paid: this.state.dueBalance,
        PaymentVS: this.state.booking.ID
      }
      fetch("/Booking/PrePay", {
        method: "POST",
        cache: "no-cache",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
    }
  }


  paymentDetails = () => {
    console.log("some details about payer");
  };

  //Load an error screen if IsSuccessful is false, page will just be something that says contact hotel

  render() {
    const showCheckout = this.state.booking ? (
      <Checkout
        booking={this.state.booking}
        dueBalance={this.state.dueBalance}
        updateBookingState={this.updateBookingState}
        paymentDetails={this.paymentDetails}
        PUTUpdateGuests={this.PUTUpdateGuests}
        POSTPrePay={this.POSTPrePay}
      />
    ) : (
      <LinearProgress />
    );

    return this.state.reservationFound ||
      this.state.reservationFound === null ? (
      showCheckout
    ) : (
      <React.Fragment>
        <CssBaseline />
        <main>
          <Paper>
            <img
              src={Logo}
              className="site-logo"
              alt="Access Accommodation Logo"
            />
            <Typography component="h1" variant="h5" align="center">
              Access Accommodation Online Check in
            </Typography>
            <React.Fragment>
              <React.Fragment>
                <Typography
                  className="no-res-msg"
                  variant="h5"
                  gutterBottom
                  align="center"
                >
                  This link is invalid. Please contact Access Accommodation for
                  further assistance.
                </Typography>
              </React.Fragment>
            </React.Fragment>
          </Paper>
          <Copyright />
        </main>
      </React.Fragment>
    );
  }
}

export default App;
