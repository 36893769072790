import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import ClearRoundedIcon from "@material-ui/icons/Clear";
import axios from "axios";
import FileBase64 from "react-file-base64";

export default class PassportScan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploading: false,
      validated: "",
      files: [],
    };
  }

  getFiles(files) {
    this.setState({ uploading: false });
    this.setState({ validated: "" });
    this.setState({ files: files });
  }

  onValidate() {
    if (this.state.files.length !== 0) {
      this.setState({ uploading: true });
      const files = this.state.files[0];
      // const name = files.name.split(".").slice(0, -1).join("");
      const imgFormat = files.type.split("/").pop();
      const imgData = files.base64.split("base64,")[1];
      const fileData = {
        InputImages: [
          {
            Name: "WhiteImage",
            ImageFormat: imgFormat,
            Data: imgData,
          },
        ],
      };

      axios({
        method: "post",
        url: "/passport/upload",
        data: fileData,
      }).then(
        response => {
          if (response.data === "Pass") {
            this.setState({ uploading: false });
            this.setState({ validated: "success" });
          } else {
            this.setState({ uploading: false });
            this.setState({ validated: "fail" });
          }
        },
        error => {
          console.log(error);
          this.setState({ uploading: false });
        }
      );
    }
  }

  render() {
    return (
			<React.Fragment>
				<Typography variant='h6' gutterBottom>
					Secure ID Scan
				</Typography>
				<Box mb={2}>
					<Typography variant='subtitle1'>
						As required for all bookings staying with us, we require
						verification of your ID. Please upload your ID or passport, that
						will be verified by our secure ID system powered by GBG PLC.
					</Typography>
				</Box>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12}>
						<FileBase64 multiple={true} onDone={this.getFiles.bind(this)} />
						<div className='text-center'>
							{this.state.files.map((file, i) => {
								return (
									<div className='scan-holder'>
										<img
											width='100%'
											key={i}
											src={file.base64}
											alt={file.name}
										/>

										{this.state.uploading && (
											<div className='validation-overlay'>
												<CircularProgress color='secondary' />
												<h3 className='text-white'>
													Processing Document. Please Wait...
												</h3>
											</div>
										)}
										{!this.state.uploading &&
											this.state.validated === "success" && (
												<div className='validation-overlay'>
													<CheckRoundedIcon className='successIcon' />
													<h3 className='success'>Document Verified!</h3>
												</div>
											)}

										{!this.state.uploading && this.state.validated === "fail" && (
											<div className='validation-overlay'>
												<ClearRoundedIcon className='failIcon' />
												<h3 className='failed'>
													Verification failed please upload a new image
												</h3>
											</div>
										)}
									</div>
								);
							})}
							<img src='' alt='' />
						</div>
						<Button
							disabled={this.state.uploading}
							variant='contained'
							color='secondary'
							className='Style'
							onClick={this.onValidate.bind(this)}
						>
							{this.state.uploading ? "Validating" : "Validate"}
						</Button>
					</Grid>
				</Grid>
				<div className={this.props.btnClasses.buttons}>
					<Button
						onClick={this.props.handleBack}
						className={this.props.btnClasses.button}
					>
						Back
					</Button>

					<Button
						disabled={!this.state.validated}
						type='submit'
						variant='contained'
						color='primary'
						className={this.props.btnClasses.button}
						onClick={this.props.handleNext}
					>
						Next
					</Button>
				</div>
			</React.Fragment>
		);
  }
}
