import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import StripeCheckout from "react-stripe-checkout";

const useStyles = makeStyles(theme => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: "700",
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));

export default function Review(props) {
  const classes = useStyles();
  const { CurrencyISO, Rooms } = props.booking;
  const DueBalance = props.dueBalance;
  // Owner removed from above as variable
  const handleToken = token => {
    const balance = {
      amount: DueBalance * 100,
      currency: CurrencyISO,
    };
    const data = { token, balance };
    fetch("/checkout", {
      method: "POST",
      cache: "no-cache",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      // We get the API response and receive data in JSON format...
      .then(response => {
        if (response.status === 200) {

          // In here we need to put the register pre payment on the guest

          finishBooking();

        } else {
          alert(
            "There seemed to be an issue with your payment, please try again or get in touch with us at info@accacc.co.uk"
          );
        }
      });
  };

  const finishBooking = e => {
    props.handleNext(e);
    props.PUTUpdateGuests();
    props.POSTPrePay();

    const data = {
      name:
        props.booking.Rooms[0].Guests[0].FirstName +
        " " +
        props.booking.Rooms[0].Guests[0].Surname,
      email: props.booking.Rooms[0].Guests[0].Email,
      booking: props.booking
    };

    console.log(data);

    fetch("/thank-you/", {
      method: "POST",
      cache: "no-cache",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  const bookingSummary = (
    <React.Fragment>
      <Typography className={classes.total} variant="h5" gutterBottom>
        Booking Summary
      </Typography>
      <Box mb={2}>
        <Typography variant="subtitle1">
          Thank you. Your ID has been verified. Please now review your booking
          and proceed to payment powered by Stripe.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Rooms
          </Typography>

          <Grid item xs={12} sm={6}>
            {Rooms.map(room => {
              return <Typography gutterBottom>Room-{room.ID}</Typography>;
            })}
          </Grid>
        </Grid>

        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Number of Guests
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={6}>
              {Rooms.map(room => {
                return (
                  <Typography gutterBottom>
                    Guests x {room.Guests.length}
                  </Typography>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <List disablePadding>
        <ListItem className={classes.listItem}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" className={classes.total}>
            {parseFloat(DueBalance).toFixed(2) + " " + CurrencyISO}
          </Typography>
        </ListItem>
      </List>
    </React.Fragment>
  );

  const balanceReconcilliation =
    DueBalance > 0 ? (
      <React.Fragment>
        <Typography variant="subtitle1">
          Please click "PAY NOW" below to pay for your booking!
        </Typography>

        <div className={props.btnClasses.buttons}>
          <Button
            onClick={props.handleBack}
            className={props.btnClasses.button}
          >
            Back
          </Button>
          <StripeCheckout
            name="Access Accommodation"
            description="Outstanding Balance Payment"
            // image="https://angloeducational.com/wp-content/uploads/2019/12/Anglo_Logo.svg"
            token={handleToken}
            stripeKey="pk_test_U9eqnTzKe2aZV4uEMrxIvi8r"
            amount={DueBalance * 100}
            currency={CurrencyISO}
            email="harry@umidigital.co.uk"
            allowRememberMe={false}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={props.btnClasses.button}
            >
              Pay Now
            </Button>
          </StripeCheckout>
        </div>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Typography variant="h5" gutterBottom>
          You have no outstanding balance.
        </Typography>
        <Typography variant="subtitle1">
          Please click "COMPLETE" below to complete your booking!
        </Typography>

        <div className={props.btnClasses.buttons}>
          <Button
            onClick={props.handleBack}
            className={props.btnClasses.button}
          >
            Back
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={props.btnClasses.button}
            onClick={finishBooking}
          >
            Complete
          </Button>
        </div>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      {bookingSummary}
      {balanceReconcilliation}
    </React.Fragment>
  );
}
