import React from "react";
import GuestForm from "./GuestForm";

export default function RoomBooking(props) {
  let n = 0; //take a record of how many guests
  const guestForms = props.booking.Guests
    ? props.booking.Guests.map(guest => (
        <GuestForm
          key={`guest-${guest.ID}`}
          roomNo={props.roomNo}
          guestNo={n++}
          guest={guest}
          updateBookingState={props.updateBookingState}
          checkedAddress={props.checkedAddress}
        />
      ))
    : null;

  return (
    <section key={`room-${props.ID}`} className="room-booking">
      {guestForms}
    </section>
  );
}
