import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import RoomBooking from "./RoomBooking";
import PassportScan from "./PassportScan";
import Payment from "./Payment";
import Logo from "./logo_access.png";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://accessaccommodationlondon.co.uk/">
        Access Accommodation. Powered by Umi.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

let storedAddress = [];

const checkedAddress = checked => {
  storedAddress = checked;
  console.log(storedAddress);
};

export default function Checkout(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const handleNext = e => {
    if (e) {
      e.preventDefault();
    }
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  const steps = ["Booking Details", "Documents ", "Review & Pay"];

  const getStepContent = (step, Props) => {
    switch (step) {
      case 0:
        let n = 0; //take a record of how many guests
        const roomBookings =
          Props.booking.Rooms.length > 0 ? (
            Props.booking.Rooms.map(room => (
              <RoomBooking
                key={`room-${room.ID}`}
                roomNo={n++}
                booking={room}
                bookingid={props.booking.id}
                updateBookingState={Props.updateBookingState}
                checkedAddress={checkedAddress}
              />
            ))
          ) : (
            <div>No Booking Found</div>
          );

        return (
          <form className="room-bookings" onSubmit={handleNext}>
            {roomBookings}

            <div className={classes.buttons}>
              {activeStep !== 0 && (
                <Button onClick={handleBack} className={classes.button}>
                  Back
                </Button>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? "Place order" : "Next"}
              </Button>
            </div>
          </form>
        );
      case 1:
        return (
          <React.Fragment>
            <PassportScan
              handleBack={handleBack}
              handleNext={handleNext}
              btnClasses={classes}
            />
          </React.Fragment>
        );

      case 2:
        return (
          <Payment
            booking={Props.booking}
            dueBalance={Props.dueBalance}
            PUTUpdateGuests={Props.PUTUpdateGuests}
            POSTPrePay={Props.POSTPrePay}
            handleBack={handleBack}
            handleNext={handleNext}
            btnClasses={classes}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <img
            src={Logo}
            className="site-logo"
            alt="Access Accommodation Logo"
          />
          <Typography component="h1" variant="h5" align="center">
            Access Accommodation Online Check in
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Your booking is now complete.
                </Typography>
                <Typography variant="subtitle1">
                  We will be in touch in the next 48 hours with details of how
                  to access your accommodation. Thank you for choosing Access
                  Accommodation for your London email:
                  <a href="mailto: info@accacc.co.uk">info@accacc.co.uk</a>
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep, props)}
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>
  );
}
